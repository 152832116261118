<template>
  <v-container>
    <v-row class="text-center mt-10">
      <v-col>
        <v-btn x-large dark color="#1db954" @click="signIn">
          Sign In with Spotify
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getSignInUrl } from "@/libs/api";

export default {
  name: "LoginView",
  computed: mapState(["accessToken"]),
  methods: {
    async signIn() {
      location.href = await getSignInUrl();
    },
  },
  mounted() {
    if (this.accessToken) {
      this.$router.push({ name: "home" });
    }
  },
};
</script>
