import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null,
    expires: null,
    displayName: null,
    userId: null,
    userPictureUrl: null,
  },
  getters: {},
  mutations: {
    setTokens(state, tokensString) {
      const { accessToken, refreshToken, expires } = JSON.parse(tokensString);
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expires = new Date(expires);
    },
    setUser(state, user) {
      state.displayName = user.display_name;
      state.userId = user.id;
      if (user.images.length > 0) {
        const images = user.images.sort((a, b) => {
          if (a.width > b.width) return 1;
          if (a.width < b.width) return -1;
          return 0;
        });
        state.userPictureUrl = images[0].url;
      }
    },
  },
  actions: {},
  modules: {},
});
