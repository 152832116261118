import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import AuthCallbackView from "../views/AuthCallbackView.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: { name: "login" } },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/auth",
    name: "authCallback",
    component: AuthCallbackView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "authCallback" && localStorage.getItem("state") !== undefined)
    next();
  else if (to.name !== "login" && store.state.accessToken === null)
    next({ name: "login" });
  else if (to.name === "login" && store.state.accessToken !== null)
    next({ name: "home" });
  else next();
});

export default router;
