<template>
  <v-app>
    <v-app-bar app color="cyan" dark clipped-left>
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

      <v-toolbar-title>Playlistinator</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-title class="mr-4">{{ displayName }}</v-toolbar-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar size="40">
              <img :src="userPictureUrl" :alt="displayName" />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logOut">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- <v-navigation-drawer app v-model="drawer" clipped>
      <v-list>
        <v-list-item to="playlist">
          <v-list-item-icon>
            <v-icon>mdi-playlist</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Playlist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { refreshTokens } from "./libs/api";

export default {
  name: "App",
  data: () => ({
    // drawer: true,
  }),
  computed: mapState([
    "accessToken",
    "expires",
    "displayName",
    "userPictureUrl",
  ]),
  methods: {
    logOut() {
      localStorage.clear();
      location.reload();
    },
    ...mapMutations(["setTokens"]),
  },
  async created() {
    if (!this.accessToken) {
      const tokensString = localStorage.getItem("tokens");
      if (tokensString) {
        this.setTokens(tokensString);
        if (this.expires < new Date()) {
          await refreshTokens();
        }
      }
    }
  },
};
</script>
