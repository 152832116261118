import store from "../store";

const CLIENT_ID = "827d4ec8ff864e9d83bfb33fddd243eb";
const AUTHORIZE_ENDPOINT = "https://accounts.spotify.com/authorize";
const TOKEN_ENDPOINT = "https://accounts.spotify.com/api/token";
const SCOPES = [
  "user-read-private",
  "playlist-read-collaborative",
  "playlist-modify-private",
  "playlist-modify-public",
];
const SPOTIFY_API_ENDPOINT = "https://api.spotify.com/v1";
const REDIRECT_URI = {
  production: "https://pl.thpd.io/auth",
  development: "http://localhost:8080/auth",
}[process.env.NODE_ENV];

function urlEncodeB64(input) {
  const b64Chars = { "+": "-", "/": "_", "=": "" };
  return input.replace(/[+/=]/g, (m) => b64Chars[m]);
}

function setTokens(data) {
  localStorage.clear();
  const tokensString = JSON.stringify({
    accessToken: data.access_token,
    refreshToken: data.refresh_token,
    expires: Date.now() + data.expires_in * 1000,
  });
  localStorage.setItem("tokens", tokensString);
  store.commit("setTokens", tokensString);
}

export async function getSignInUrl() {
  const state = crypto.randomUUID();
  localStorage.setItem("state", state);

  const code_verifier = crypto.randomUUID() + "-" + crypto.randomUUID();
  localStorage.setItem("code_verifier", code_verifier);

  const msgUint8 = new TextEncoder().encode(code_verifier);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const code_challenge = urlEncodeB64(
    window.btoa(String.fromCharCode(...hashArray))
  );

  const queryParams = new URLSearchParams({
    response_type: "code",
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    scope: SCOPES.join(" "),
    state: state,
    code_challenge_method: "S256",
    code_challenge: code_challenge,
  });
  return `${AUTHORIZE_ENDPOINT}?${queryParams}`;
}

export async function handleAuthCallback(code) {
  const postParams = new URLSearchParams({
    grant_type: "authorization_code",
    code: code,
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    code_verifier: localStorage.getItem("code_verifier"),
  });
  const response = await fetch(TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: postParams,
  });
  if (!response.ok) {
    const text = await response.text();
    throw text;
  } else {
    const data = await response.json();
    setTokens(data);
  }
}

export async function refreshTokens() {
  console.log("Refreshing tokens");
  const refreshParams = new URLSearchParams({
    grant_type: "refresh_token",
    refresh_token: store.state.refreshToken,
    client_id: CLIENT_ID,
  });
  const response = await fetch(TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: refreshParams,
  });
  if (!response.ok) {
    const msg = await response.text();
    console.error(`Failed to refresh tokens: ${msg}`);
  } else {
    const tokens = await response.json();
    setTokens(tokens);
  }
}

export async function apiRequest(
  uri,
  method = "GET",
  body = null,
  absolute = false
) {
  if (store.state.expires < new Date()) {
    await refreshTokens();
  }
  const requestOptions = {
    method: method,
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  };
  if (body) {
    requestOptions.headers["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(body);
  }

  if (!absolute) uri = SPOTIFY_API_ENDPOINT + uri;
  const resp = await fetch(uri, requestOptions);
  if (!resp.ok) {
    const msg = await resp.text();
    alert(`Error sending API request: ${msg}`);
  } else {
    const data = await resp.json();
    if (data.next) {
      const nextPage = await apiRequest(data.next, method, body, true);
      return { items: data.items.concat(nextPage.items) };
    } else {
      return data;
    }
  }
}
