<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedPlaylist"
          :items="playlists"
          :loading="isLoading"
          placeholder="Select a playlist..."
          item-text="name"
          item-value="id"
          hide-no-data
          hide-selected
          return-object
          cache-items
          @change="loadSongs"
        >
          <template v-slot:item="data">
            <v-list-item-avatar tile>
              <img :src="data.item.imageUrl" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ data.item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(selectedPlaylist).length > 0">
      <v-col>
        <v-card>
          <v-card-title>
            Playlist
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="shufflePlaylist">
                  <v-list-item-title>Shuffle Playlist</v-list-item-title>
                </v-list-item>
                <v-list-item @click="userBasedSortPlaylist">
                  <v-list-item-title>
                    Sort Playlist by Alternating Users
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>

          <v-card-text class="text-center" v-if="songs.length == 0">
            No songs in playlist.
          </v-card-text>

          <v-list two-line>
            <!-- TODO drag functionality -->
            <v-list-item v-for="song in songs" :key="song.id">
              <v-list-item-avatar tile>
                <v-img :src="song.imageUrl"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{ song.title }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ song.artists }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { apiRequest } from "@/libs/api";
import { mapState, mapMutations } from "vuex";

export default {
  name: "HomeView",
  data: () => ({
    selectedPlaylist: {},
    songs: [],
    playlists: [],
    isLoading: false,
    search: undefined,
    nowPlaying: undefined,
    isPlaying: false,
  }),
  computed: mapState(["displayName"]),
  methods: {
    async queryPlaylists() {
      if (this.isLoading) return;

      this.isLoading = true;
      const data = await apiRequest("/me/playlists");
      this.playlists = data.items.map((item) => {
        const images = item.images.sort((a, b) => {
          if (a.width > b.width) return 1;
          if (a.width < b.width) return -1;
          return 0;
        });
        const imageURL = images.length > 0 ? images[0].url : "";
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          imageUrl: imageURL,
        };
      });
      this.isLoading = false;
    },
    async loadSongs() {
      const data = await apiRequest(
        `/playlists/${this.selectedPlaylist.id}/tracks`
      );
      this.songs = data.items.map((item) => {
        const images = item.track.album.images.sort((a, b) => {
          if (a.width > b.width) return 1;
          if (a.width < b.width) return -1;
          return 0;
        });
        return {
          id: item.track.id,
          title: item.track.name,
          artists: item.track.artists.map((e) => e.name).join(", "),
          imageUrl: images[0].url,
          uri: item.track.uri,
          duration_ms: item.track.duration_ms,
          submitter: item.added_by.id,
        };
      });
      document.activeElement.blur();
    },
    async shufflePlaylist() {
      let tempSongs = this.songs.slice();
      let currentIndex = tempSongs.length;
      let randomIndex;

      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [tempSongs[currentIndex], tempSongs[randomIndex]] = [
          tempSongs[randomIndex],
          tempSongs[currentIndex],
        ];
      }
      await apiRequest(`/playlists/${this.selectedPlaylist.id}/tracks`, "PUT", {
        uris: tempSongs.map((song) => song.uri),
      });
      this.loadSongs();
    },
    async userBasedSortPlaylist() {
      let userSeparatedList = {};
      this.songs.forEach((song) => {
        if (!userSeparatedList[song.submitter])
          userSeparatedList[song.submitter] = [song.uri];
        else userSeparatedList[song.submitter].push(song.uri);
      });
      let newPlaylist = [];
      while (Object.keys(userSeparatedList).length > 0) {
        Object.keys(userSeparatedList).forEach((user) => {
          let song = userSeparatedList[user].shift();
          if (song) newPlaylist.push(song);
          else delete userSeparatedList[user];
        });
      }
      await apiRequest(`/playlists/${this.selectedPlaylist.id}/tracks`, "PUT", {
        uris: newPlaylist,
      });
      this.loadSongs();
    },
    ...mapMutations(["setUser"]),
  },
  async mounted() {
    if (this.displayName === null) {
      const data = await apiRequest("/me");
      this.setUser(data);
    }
    await this.queryPlaylists();
  },
};
</script>
