<template>
  <v-container>
    <v-row class="text-center mt-10">
      <v-col v-if="error === null">
        <p class="text-body-1">Logging in...</p>
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-col>
      <v-col v-else>
        <v-alert text type="error">Error logging in: {{ error }}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { handleAuthCallback } from "@/libs/api";

export default {
  name: "AuthCallbackView",
  data: () => ({
    error: null,
  }),
  async mounted() {
    if (this.$route.query.state != localStorage.getItem("state")) {
      console.error("state mismatch");
      this.error = "Malicious login detected.";
    } else if (this.$route.query.error) {
      this.error = this.$route.query.error;
    } else {
      try {
        await handleAuthCallback(this.$route.query.code);
        this.$router.push({ name: "home" });
      } catch (error) {
        this.error = error;
      }
    }
  },
};
</script>
